import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as FGetToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import debouce from "lodash/debounce";

const firebaseConfig = {
  apiKey: "AIzaSyChjY7QpBjD5D3nvK5EnkHT7V44ebBmM2c",
  authDomain: "salaktookrangwan.firebaseapp.com",
  projectId: "salaktookrangwan",
  storageBucket: "salaktookrangwan.appspot.com",
  messagingSenderId: "795369310252",
  appId: "1:795369310252:web:44f05886fa8967edd26989",
  measurementId: "G-1LSV76Q5BM",
};

const msgKey =
  "BHRwTW5iDdsy-I-_VSO-a4MHF5TGqChXQLqAwIQZ3DAe7OZpKlBu-nHHWPc40wzq_HjhVrKnD_0vw7aFZv_hTMk";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getToken = async (): Promise<string | null> => {
  try {
    let currentToken = await FGetToken(messaging, { vapidKey: msgKey });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    throw error;
  }
};

export const onMessageListener = (
  cb: (data: MessagePayload) => void
): Promise<MessagePayload> =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      cb(payload);
      debouncedPlaySound();
      resolve(payload);
    });
  });

function playSound() {
  var audio = new Audio("/audio/new-order.mp3");
  audio.play();
}

// Usage:
// This will create a debounced version of the playSound function
// that, when called repeatedly, will only actually invoke playSound
// at most once every 1 second.
var debouncedPlaySound = debouce(() => {
  playSound();
}, 1000);
